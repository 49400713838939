@import '../../../../scss/theme-bootstrap';

.gnav-section-formatter {
  @include breakpoint($landscape-up) {
    background-color: $color-light-gray;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    opacity: 1;
    position: relative;
    width: 100%;
    @include breakpoint($landscape-up) {
      background-color: $color-white;
      display: flex;
      max-height: 480px;
      overflow-y: auto;
      padding: 25px 0 80px;
      height: 100%;
      flex-direction: row;
      width: 100vw;
      position: relative;
      #{$ldirection}: 50%;
      #{$rdirection}: 50%;
      margin-#{$ldirection}: -50vw;
      margin-#{$rdirection}: -50vw;
      max-width: none;
      justify-content: center;
      overflow-x: hidden;
    }
    @include breakpoint($desktop-site-width) {
      max-height: $gnav-content-max-height;
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      @include breakpoint($landscape-up) {
        background: $color-neutral-black;
      }
    }
    // gnav section hidden state
    .gnav-section-formatter[aria-expanded='false'] & {
      flex-direction: column;
      flex-basis: auto;
      height: 0;
      opacity: 0;
      position: absolute;
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
    &--inner {
      @include breakpoint($landscape-up) {
        display: flex;
        min-width: 100vw;
        max-width: $container-max-width;
        margin-#{$rdirection}: auto;
        margin-#{$ldirection}: auto;
        padding: 0 42px;
      }
      @include breakpoint($container-max-width-landscape) {
        min-width: $container-max-width;
      }
    }
  }
  &__gnav-links-wrapper {
    display: flex;
    flex-direction: column;
    height: 0;
    order: 2;
    width: 100%;
    @include breakpoint($landscape-up) {
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: row;
      max-width: 100%;
      position: relative;
      order: unset;
      flex: 1;
    }
    .gnav-section-formatter--hide-touts & {
      .gnav-tout {
        display: none;
        @include breakpoint($landscape-up) {
          display: inherit;
        }
      }
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-last & {
      order: 1;
    }
    .gnav-section-formatter[aria-expanded='true'] & {
      height: auto;
      margin-top: 18px;
      @include breakpoint($landscape-up) {
        height: 100%;
        margin-top: 45px;
        width: auto;
      }
    }
    // default expanded layout is grid-1 from cms selection
    // .gnav-section-formatter--grid-1 (6 even columns)
    > div {
      padding: 0;
      @include breakpoint($landscape-up) {
        flex: 0 22%;
        padding-#{$ldirection}: 40px;
      }
    }
    > .gnav-links:not(.gnav-links--columns-break) {
      flex: 0 12.5%;
      @include breakpoint($landscape-up) {
        flex: 0 20%;
      }
    }
  }
  .gnav-links {
    &__parent {
      @include breakpoint($landscape-up) {
        align-items: baseline;
        display: flex;
        flex-direction: column;
        padding-#{$ldirection}: 45px;
      }
      .gnav-links {
        margin: 0;
      }
    }
  }
  .gnav-tout {
    display: none;
    @include breakpoint($landscape-up) {
      flex: none;
      margin-#{$ldirection}: 40px;
      padding: 0 0 80px;
    }
    @include breakpoint($extra-large-up) {
      display: block;
      width: 317px;
    }
    &__headline,
    &__copy {
      font-family: $font-family-knockout;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
    &__headline {
      color: $color-light-black;
      font-size: 13px;
      font-weight: bold;
      line-height: 1.6;
      margin-top: 10px;
      text-transform: uppercase;
    }
    &__copy {
      color: $color-darker-gray;
      font-size: 14px;
      line-height: 1.5;
    }
    &__link {
      a {
        display: block;
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  &__static-links-wrapper {
    .gnav-section-formatter[aria-expanded='true'] & {
      @include breakpoint($landscape-up) {
        margin-top: 45px;
      }
    }
  }
  &__tout {
    @include swap_direction(padding, 20px $gnav-mobile-hpad--outer 0);
    order: 1;
    @include breakpoint($landscape-up) {
      display: none;
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-last & {
      order: 2;
      padding-top: 0;
    }
  }
  a {
    text-decoration: none;
  }
  &__static-link {
    font-size: 21px;
    line-height: 1.2;
    margin-bottom: 6px;
    @include breakpoint($landscape-up) {
      font-size: 28px;
      line-height: 1.1;
      margin-bottom: 18px;
    }
  }
  .gnav-static-link {
    padding-#{$ldirection}: 20px;
    font-size: 21px;
    font-family: $font--heading;
    color: $color-light-black;
    line-height: get-line-height(25px, 21px);
    letter-spacing: -0.03em;
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 0;
      font-size: 28px;
      line-height: get-line-height(31px, 28px);
      &:focus,
      &:hover {
        color: $color-light-black;
      }
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      color: $color-white;
      @include breakpoint($landscape-up) {
        &:focus,
        &:hover {
          color: $color-neutral-gray;
        }
      }
    }
  }
  &--alignment {
    .gnav-section-formatter__static-links-wrapper {
      margin-#{$rdirection}: 20px;
      width: 30%;
    }
    .gnav-section-formatter__gnav-links-wrapper {
      @include breakpoint($landscape-up) {
        justify-content: flex-start;
      }
      .gnav-links {
        @include breakpoint($landscape-up) {
          flex-basis: auto;
        }
      }
    }
  }
}

// Discover menu section links
.header-nav-section__section:nth-of-type(2) {
  padding-bottom: 12px;
  @include breakpoint($landscape-up) {
    padding-bottom: initial;
  }
  .gnav-section-formatter {
    &__static-link {
      line-height: 2.5;
      @include breakpoint($landscape-up) {
        line-height: 1.1;
      }
    }
    .gnav-static-link {
      font-size: 14px;
      line-height: get-line-height(21px, 14px);
      letter-spacing: 0.01em;
      font-family: $font--text;
      color: $color-darker-gray;
      @include breakpoint($landscape-up) {
        font-size: 28px;
        line-height: get-line-height(31px, 28px);
        letter-spacing: normal;
        font-family: $font--heading;
        color: $color-light-black;
      }
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        color: $color-white;
        @include breakpoint($landscape-up) {
          &:focus,
          &:hover {
            color: $color-neutral-gray;
          }
        }
      }
    }
    &__gnav-links-wrapper {
      [aria-expanded='true'] & {
        margin-top: 0;
        @include breakpoint($landscape-up) {
          margin-top: 45px;
        }
      }
      .gnav-tout {
        @include breakpoint($landscape-up) {
          display: block;
          margin-#{$ldirection}: 0;
          width: 159px;
        }
        @include breakpoint($extra-large-up) {
          width: 317px;
        }
        &__copy {
          display: none;
          @include breakpoint($extra-large-up) {
            display: block;
          }
        }
      }
    }
  }
}
